import { useStore } from 'vuex';
import axios from 'axios';

export default () => {
  const store = useStore();

  const fetchVessel = (id) => {
    return axios.get(`/api/vessels/${id}`).then(({ data }) => {
      store.commit('vessels/setDetails', data);
    });
  };

  const fetchVessels = () => {
    return axios.get('/api/vessel').then(({ data }) => {
      store.commit('vessels/setList', data);
    });
  };

  const vessels = () => {
    return store.state.vessels.list;
  };

  const vesselDetails = () => {
    return store.state.vessels.details;
  };

  return {
    fetchVessel,
    fetchVessels,
    vessels,
    vesselDetails,
  };
};
