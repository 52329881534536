<template>
  <div class="speed-fuel">
    <div class="sidebar">
      <a-skeleton v-if="loading" class="details details-skeleton" active />

      <div class="details" v-else>
        <div class="details-inner">
          <div class="details-header">
            <ul class="details-list basic clean">
              <li>
                <div>
                  <a-typography-text type="secondary">
                    {{$t('fleet-page.analyzed period')}}
                  </a-typography-text>
                </div>

                <a-select placeholder="12 months" style="width: 174px" value="1">
                  <a-select-option value="1">12 months</a-select-option>
                </a-select>
              </li>
            </ul>
          </div>

          <div class="details-body">
            <ul class="details-list clean">
              <li>
                <a-typography-text type="secondary">
                  {{$t('fleet-page.data sources')}}
                </a-typography-text>
                <a-typography-text>
                  {{ vessel.fuel_consumption_report.data_sources.join(", ") }}
                </a-typography-text>
              </li>
            </ul>

            <a-statistic
              class="statistic-middle"
              :value="
                kFormatter.format(
                  vessel.fuel_consumption_report.summary.utilization_rate.value
                )
              "
            >
              <template #title>
                <a-typography-title :level="4">
                  Vessel utilization rate
                </a-typography-title>
              </template>
              <template #suffix>
                <a-space align="center">
                  <a-typography-text>%</a-typography-text>

                  <div class="icon-arrow-rotated">
                    <img
                      v-if="
                        vessel.fuel_consumption_report.summary.utilization_rate
                          .YTY !== 0
                      "
                      :src="
                        vessel.fuel_consumption_report.summary.utilization_rate
                          .YTY > 0
                          ? icons.arrowGreenDown
                          : icons.arrowRedUp
                      "
                      alt="arrow"
                    />
                  </div>

                  <sup
                    class="statistic-sup"
                    v-if="
                      vessel.fuel_consumption_report.summary.utilization_rate
                        .YTY !== 0
                    "
                  >
                    <a-typography-text
                      :type="
                        vessel.fuel_consumption_report.summary.utilization_rate
                          .YTY > 0
                          ? 'success'
                          : 'danger'
                      "
                    >
                      {{
                        kFormatter.format(
                          vessel.fuel_consumption_report.summary
                            .utilization_rate.YTY,
                            "%"
                        )
                      }}
                    </a-typography-text>
                  </sup>
                </a-space>
              </template>
            </a-statistic>

            <div class="overview-chart">
              <OverviewChart
                :range="[
                  {
                    label: $t('fleet-page.at sea'),
                    color: '#00BC4B',
                    value: vessel.fuel_consumption_report.summary.at_sea.value,
                    yty: vessel.fuel_consumption_report.summary.at_sea.YTY,
                  },
                  {
                    label: $t('fleet-page.at port'),
                    color: '#F2F200',
                    value: vessel.fuel_consumption_report.summary.at_port.value,
                    yty: vessel.fuel_consumption_report.summary.at_port.YTY,
                  },
                  {
                    label: $t('fleet-page.at anchor'),
                    color: '#FFA800',
                    value:
                      vessel.fuel_consumption_report.summary.at_anchor.value,
                    yty: vessel.fuel_consumption_report.summary.at_anchor.YTY,
                  },
                  {
                    label: $t('fleet-page.non reported'),
                    color: '#818699',
                    value:
                      vessel.fuel_consumption_report.summary.non_reported.value,
                    yty: vessel.fuel_consumption_report.summary.non_reported
                      .YTY,
                  },
                ]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="chart">
      <a-row :gutter="[10, 0]" class="flex-2 mb-8">
        <a-col :span="24">
          <div class="card">
            <div class="card-body is-black">
              <a-radio-group
                v-model:value="state.filter"
                @change="changeTab"
                button-style="solid"
              >
                <a-radio-button value="total">{{$t('fleet-page.total')}}</a-radio-button>
                <a-radio-button value="at_sea">{{$t('fleet-page.at sea')}}</a-radio-button>
                <a-radio-button value="at_port">{{$t('fleet-page.at port')}}</a-radio-button>
                <a-radio-button value="at_anchor">{{$t('fleet-page.at anchor')}}</a-radio-button>
              </a-radio-group>

              <div
                class="card-statistic"
                v-if="
                  vessel.fuel_consumption_report != null &&
                  vessel.fuel_consumption_report[state.filter]
                "
              >
                <a-row :gutter="[60, 0]">
                  <a-col :span="6" class="statistic-first">
                    <a-statistic
                      class="statistic-middle"
                      :value="
                        kFormatter.format(
                          vessel.fuel_consumption_report[state.filter].total
                            .value
                        )
                      "
                    >
                      <template #title>
                        <a-typography-text>{{$t('fleet-page.total consumption')}}</a-typography-text>
                      </template>
                      <template
                        #suffix
                        v-if="
                          vessel.fuel_consumption_report[state.filter].total
                            .YTY !== 0
                        "
                      >
                        <a-space align="center">
                          <div class="icon-arrow">
                            <img
                              v-if="
                                vessel.fuel_consumption_report[state.filter]
                                  .total.YTY !== 0
                              "
                              :src="
                                vessel.fuel_consumption_report[state.filter]
                                  .total.YTY < 0
                                  ? icons.arrowGreenDown
                                  : icons.arrowRedUp
                              "
                              alt="arrow"
                            />
                          </div>

                          <sup class="statistic-sup">
                            <a-typography-text
                              :type="
                                vessel.fuel_consumption_report[state.filter]
                                  .total.YTY < 0
                                  ? 'success'
                                  : 'danger'
                              "
                            >
                              {{
                                kFormatter.format(
                                  vessel.fuel_consumption_report[state.filter]
                                    .total.YTY,
                                    "%"
                                )
                              }}
                            </a-typography-text>
                          </sup>
                        </a-space>
                      </template>
                    </a-statistic>
                    <div class="statistic-footer">{{$t('units.mts')}}</div>
                  </a-col>

                  <a-col :span="4">
                    <a-statistic
                      :value="
                        kFormatter.format(
                          vessel.fuel_consumption_report[state.filter]
                            .main_engine.value
                        )
                      "
                    >
                      <template #title>
                        <a-badge color="#f50" :text="$t('fleet-page.main engine')" />
                      </template>
                      <template
                        #suffix
                        v-if="
                          vessel.fuel_consumption_report[state.filter]
                            .main_engine.YTY !== 0
                        "
                      >
                        <a-space align="center">
                          <div class="icon-arrow">
                            <img
                              v-if="
                                vessel.fuel_consumption_report[state.filter]
                                  .main_engine.YTY !== 0
                              "
                              :src="
                                vessel.fuel_consumption_report[state.filter]
                                  .main_engine.YTY < 0
                                  ? icons.arrowGreenDown
                                  : icons.arrowRedUp
                              "
                              alt="arrow"
                            />
                          </div>

                          <sup>
                            <a-typography-text
                              :type="
                                vessel.fuel_consumption_report[state.filter]
                                  .main_engine.YTY < 0
                                  ? 'success'
                                  : 'danger'
                              "
                            >
                              {{
                                kFormatter.format(
                                  vessel.fuel_consumption_report[state.filter]
                                    .main_engine.YTY,
                                    "%"
                                )
                              }}
                            </a-typography-text>
                          </sup>
                        </a-space>
                      </template>
                    </a-statistic>
                    <div class="statistic-footer">{{$t('units.mts')}}</div>
                  </a-col>

                  <a-col :span="4">
                    <a-statistic
                      :value="
                        kFormatter.format(
                          vessel.fuel_consumption_report[state.filter].auxiliary
                            .value
                        )
                      "
                    >
                      <template #title>
                        <a-badge color="#FFA800" :text="$t('fleet-page.auxiliary')" />
                      </template>
                      <template
                        #suffix
                        v-if="
                          vessel.fuel_consumption_report[state.filter].auxiliary
                            .YTY !== 0
                        "
                      >
                        <a-space align="center">
                          <div class="icon-arrow">
                            <img
                              v-if="
                                vessel.fuel_consumption_report[state.filter]
                                  .auxiliary.YTY !== 0
                              "
                              :src="
                                vessel.fuel_consumption_report[state.filter]
                                  .auxiliary.YTY < 0
                                  ? icons.arrowGreenDown
                                  : icons.arrowRedUp
                              "
                              alt="arrow"
                            />
                          </div>

                          <sup>
                            <a-typography-text
                              :type="
                                vessel.fuel_consumption_report[state.filter]
                                  .auxiliary.YTY < 0
                                  ? 'success'
                                  : 'danger'
                              "
                            >
                              {{
                                kFormatter.format(
                                  vessel.fuel_consumption_report[state.filter]
                                    .auxiliary.YTY,
                                    "%"
                                )
                              }}
                            </a-typography-text>
                          </sup>
                        </a-space>
                      </template>
                    </a-statistic>
                    <div class="statistic-footer">{{$t('units.mts')}}</div>
                  </a-col>

                  <a-col :span="4">
                    <a-statistic
                      :value="
                        kFormatter.format(
                          vessel.fuel_consumption_report[state.filter].other
                            .value
                        )
                      "
                    >
                      <template #title>
                        <a-badge color="#587AFF" :text="$t('fleet-page.other')" />
                      </template>
                      <template
                        #suffix
                        v-if="
                          vessel.fuel_consumption_report[state.filter].other
                            .YTY !== 0
                        "
                      >
                        <a-space align="center">
                          <div class="icon-arrow">
                            <img
                              v-if="
                                vessel.fuel_consumption_report[state.filter]
                                  .other.YTY !== 0
                              "
                              :src="
                                vessel.fuel_consumption_report[state.filter]
                                  .other.YTY < 0
                                  ? icons.arrowGreenDown
                                  : icons.arrowRedUp
                              "
                              alt="arrow"
                            />
                          </div>

                          <sup>
                            <a-typography-text
                              :type="
                                vessel.fuel_consumption_report[state.filter]
                                  .other.YTY < 0
                                  ? 'success'
                                  : 'danger'
                              "
                            >
                              {{
                                kFormatter.format(
                                  vessel.fuel_consumption_report[state.filter]
                                    .other.YTY,
                                    "%"
                                )
                              }}
                            </a-typography-text>
                          </sup>
                        </a-space>
                      </template>
                    </a-statistic>
                    <div class="statistic-footer">{{$t('units.mts')}}</div>
                  </a-col>

                  <a-col :span="6" class="statistic-last">
                    <a-statistic
                      :value="
                        kFormatter.format(
                          vessel.fuel_consumption_report[state.filter]
                            .cii_impact.value
                        )
                      "
                    >
                      <template #title>
                        <a-typography-text>
                          {{
                            state.filter === "total"
                              ? $t("fleet-page.CII total")
                              : $t("fleet-page.CII impact")
                          }}
                        </a-typography-text>
                      </template>
                      <template
                        #suffix
                        v-if="
                          vessel.fuel_consumption_report[state.filter]
                            .cii_impact.YTY !== 0
                        "
                      >
                        <a-space align="center">
                          <div class="icon-arrow">
                            <img
                              v-if="
                                vessel.fuel_consumption_report[state.filter]
                                  .cii_impact.YTY !== 0
                              "
                              :src="
                                vessel.fuel_consumption_report[state.filter]
                                  .cii_impact.YTY < 0
                                  ? icons.arrowGreenDown
                                  : icons.arrowRedUp
                              "
                              alt="arrow"
                            />
                          </div>

                          <sup>
                            <a-typography-text
                              :type="
                                vessel.fuel_consumption_report[state.filter]
                                  .cii_impact.YTY < 0
                                  ? 'success'
                                  : 'danger'
                              "
                            >
                              {{
                                kFormatter.format(
                                  vessel.fuel_consumption_report[state.filter]
                                    .cii_impact.YTY,
                                    '%'
                                )
                              }}
                            </a-typography-text>
                          </sup>
                        </a-space>
                      </template>
                    </a-statistic>
                  </a-col>
                </a-row>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>

      <template
        v-if="
          vessel.fuel_consumption_report != null &&
          vessel.fuel_consumption_report[state.filter]
        "
      >
        <FleetFuelChartLine
          v-if="state.filter === 'total'"
          :dataSource="vessel.fuel_consumption_report[state.filter].charts"
        />

        <FleetFuelChartBar
          v-if="state.filter === 'at_sea'"
          :dataSource="vessel.fuel_consumption_report[state.filter].charts"
        />

        <FleetFuelChartBar
          v-if="state.filter === 'at_port'"
          :dataSource="vessel.fuel_consumption_report[state.filter].charts"
        />

        <FleetFuelChartBar
          v-if="state.filter === 'at_anchor'"
          :dataSource="vessel.fuel_consumption_report[state.filter].charts"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { icons } from "@/assets";
import { defineComponent, reactive, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { kFormatter } from "@/helpers";
import OverviewChart from "./OverviewChart";

import FleetFuelChartLine from "./FleetFuelChartLine.vue";
import FleetFuelChartBar from "./FleetFuelChartBar.vue";

export default defineComponent({
  name: "FleetFuelConsumption",

  props: {
    vessel: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: () => true,
    },
  },

  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({ filter: "total" });

    watchEffect(() => {
      state.filter = route.params.filter;
    });

    const changeTab = () => {
      router.push({
        name: "Fleet",
        params: {
          imo: props.vessel.imo,
          tab: route.params.tab,
          filter: state.filter,
        },
      });
    };

    return {
      icons,
      state,
      kFormatter,
      changeTab,
    };
  },

  components: {
    OverviewChart,
    FleetFuelChartLine,
    FleetFuelChartBar,
  },
});
</script>

<style lang="less" scoped>
.speed-fuel {
  display: flex;
  width: 100%;

  .chart {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .overview-chart {
    margin-top: 4rem;
  }

  .sidebar {
    width: 43.5rem;
    background-color: @color-white;
    box-shadow: -0.3rem 0 0.6rem rgba(@color-black, 0.25);
    border-right: 0.1rem solid @color-border;

    .details {
      width: 43.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      z-index: 2;
      overflow-y: auto;

      &.details-skeleton {
        padding: 1.2rem 2.4rem;
      }

      &-header {
        background-color: @color-white-panel;
        border-right: 0.1rem solid @color-border;
        padding: 1.6rem 2.5rem;



        .details-list {
          margin-bottom: 0;
        }
      }

      &-body {
        padding: 3.2rem 2.5rem;
        padding-top: 0;

        h4.ant-typography {
          font-size: 1.8rem;
          margin-bottom: 12px;
        }
      }

      &-list {
        list-style: none;
        margin-bottom: 5.4rem;
        padding: 0;

        &.clean li {
          margin-left: 0;
        }

        &.basic li {
          display: flex;
          flex-direction: row;
          align-items: stretch;
          border-bottom: none;
          margin-bottom: 12px;
        }

        &.basic li .ant-typography {
          background-color: @color-white-panel;
        }

        li {
          border-bottom: 0.1rem dashed @color-border;
          font-size: 1.6rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0.8rem 0 0 2rem;

          &.not-bordered {
            border: 0;
          }

          .ant-btn {
            top: 0.8rem;
            width: 100%;
          }

          .ant-typography {
            //background-color: @color-black;
            padding: 0 0.5rem;
            top: 0.8rem;
            position: relative;
          }
        }
      }

      &-alert {
        width: 100%;
        border: 0.1rem solid @color-red;
        border-radius: 0px;
        color: @color-red;
        display: flex;
        align-items: center;
        font-size: 1.6rem;
        justify-content: center;
        position: relative;
        padding: 0.4rem 0;
        margin-top: 2rem;

        img {
          position: absolute;
          top: 50%;
          left: 1.2rem;
          transform: translateY(-50%);
        }
      }
    }
  }

  .icon-arrow {
    display: flex;
    align-items: center;
    &-rotated{
      transform: rotate(180deg);
    }

    img {
      height: 2rem;

      .media-xl({
        height: 1.8rem;
      });
    }
  }

  .card {
    background-color: @color-white;
    margin-bottom: 1rem;
    height: 100%;

    &-title {
      color: @color-secondary;
      font-size: 1.8rem;
      padding: 1.6rem;
      height: 60px;

      .media-xl({
        font-size: 1.6rem;
      });
    }

    &-body {
      background-color: @color-white;//lighten(@color-black, 2%);
      box-shadow: 0 0.2rem 0.4rem rgba(@color-dark, 0.1);
      padding: 3.2rem 3.6rem;
      position: relative;
      z-index: 2;
      height: calc(100% - 60px);

      &.is-black {
        background-color: @color-white;
        box-shadow: none;
        position: relative;
        z-index: 1;

        .ant-radio-group:deep {
          .ant-radio-button-wrapper {
            &:not(.ant-radio-button-wrapper-checked){
              border-color: @input-border-color;
            }
          }
        }
      }

      h3.ant-typography,
      h5.ant-typography {
        font-weight: 400;
      }
    }

    &-inner {
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;
    }

    &-charts {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &-statistic {
      margin-top: 3.2rem;

      .statistic-sup {
        font-size: 1.6rem;
        position: relative;
        top: -1.8rem;

        .media-xl({
          font-size: 1.2rem;
        });
      }

      .statistic-first {
        border-right: 1px solid @color-border;
      }

      .statistic-last {
        border-left: 1px solid @color-border;
      }

      .statistic-footer {
        color: #9fa2ae;
      }

      &-group {
        border-top: 0.1rem solid @color-border;
        display: flex;
        align-items: center;
        margin-top: 1.6rem;

        .ant-statistic {
          padding-top: 1.2rem;
          line-height: 1.2;
          flex: 1;

          &:not(:first-child) {
            border-left: 0.1rem solid @color-border;
            padding-left: 1.6rem;
          }
        }
      }
    }
  }

  &:deep(.ant-badge) {
    width: 20rem;

    &-status-dot {
      width: 1.4rem;
      height: 1.4rem;
    }

    &-status-text {
      font-size: 1.6rem;
    }
  }
}
</style>