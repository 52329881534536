<template>
  <div class="layout-chart">
    <vue-echarts
      ref="chartRef"
      :option="state.option"
      :style="`height: 100%`"
      autoresize
    />
  </div>
</template>

<script>
import { defineComponent, ref, watchEffect, reactive } from "vue";
import { VueEcharts } from "vue3-echarts";
import { format } from "date-fns";

import { date } from "@/helpers";

export default defineComponent({
  name: "FleetFuelChartBar",

  props: {
    dataSource: {
      type: Object,
      default: () => {},
    },
  },

  setup(props) {
    const chartRef = ref(null);

    const state = reactive({
      option: {
        grid: {
          top: "6%",
          left: "5%",
          right: "4%",
          bottom: "6%",
        },
        xAxis: {
          type: "category",
          splitLine: {
            lineStyle: {
              color: "rgba(75, 76, 83, 0.5)",
            },
          },
          axisTick: {
            color: "rgba(75, 76, 83, 0.5)",
          },
          axisLine: {
            lineStyle: {
              width: 2,
              color: "rgba(75, 76, 83, 0.5)",
            },
          },
          axisLabel: {
            show: true,
            color: "#9FA2AE",
            fontSize: 14,
            formatter: (value) => `${format(new Date(value), "dd MMM")}`,
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              color: "rgba(75, 76, 83, 0.5)",
            },
          },
          axisLine: { onZero: false },
          axisLabel: {
            show: true,
            color: "#9FA2AE",
            fontSize: 14,
          },
        },
      },
    });

    watchEffect(() => {
      if (props.dataSource) {
        const { main, auxiliary, other } = props.dataSource;

        state.option.xAxis.data = main.map((item) => [
          date.formatFromUnixUTC(item[0], "dd MMM yyyy HH:mm:ss"),
        ]);

        state.option.series = [
          {
            type: "bar",
            stack: "value",
            barWidth: 5,
            itemStyle: {
              color: "#587AFF",
            },
            data: other.map((item) => item[1]),
          },
          {
            type: "bar",
            stack: "value",
            barWidth: 5,
            itemStyle: {
              color: "#FFA800",
            },
            data: auxiliary.map((item) => item[1]),
          },
          {
            type: "bar",
            stack: "value",
            barWidth: 5,
            itemStyle: {
              color: "#EE3440",
            },
            data: main.map((item) => item[1]),
          },
        ];

        if (chartRef.value) {
          chartRef.value.refreshOption();
        }
      }
    });

    return {
      state,
      chartRef,
    };
  },

  components: {
    VueEcharts,
  },
});
</script>

<style lang="less" scoped>
.layout-chart {
  width: 100%;
  height: 100%;
}
</style>