<template>
  <div class="chart">
    <div class="chart-container">
      <div class="chart-inner" :style="`width: ${size}rem`">
        <vue-echarts
          :option="option"
          :style="`height: ${size}rem`"
          autoresize
        />
      </div>
    </div>

    <div class="ships-grade">
      <div class="ships-grade-item" v-for="(item, index) in range" :key="index">
        <a-badge :color="item.color" :text="item.label" />

        <div class="ships-grade-content">
          <a-typography-text>
            {{ kFormatter.format(item.value, "days") }}
          </a-typography-text>

          <PercentDirection class="ships-grade-item-data" :value="parseFloat(item.yty)" :inverted="index === 0" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { VueEcharts } from "vue3-echarts";
import { kFormatter } from "@/helpers";

export default defineComponent({
  name: "OverviewChart",

  props: {
    size: {
      type: Number,
      default: 28.6,
    },
    range: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    return {
      kFormatter,
      option: {
        color: Object.values(props.range.map((item) => item.color)),
        series: [
          {
            type: "pie",
            renderer: 'svg',
            radius: ["45%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderColor: "#FFFFFF",
              borderWidth: 1,
            },
            labelLine: {
              show: false,
            },
            silent: true,
            data: Object.values(props.range.map((item) => item.value)),
          },
        ],
      },
    };
  },

  components: {
    VueEcharts,
  },
});
</script>

<style lang="less" scoped>
.chart {
  display: flex;
  flex-direction: column;
  align-items: center;

  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .vue-echarts {
      margin-top: -1.6rem;
      transform: scale(1.1);
    }
  }

  .ships-grade {
    width: 100%;
    margin-top: 1.6rem;

    &-item {
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      margin-top: 1rem;
      justify-content: space-between;

      &.is-disabled {
        opacity: 0.4;
      }

      &:deep(.ant-badge) {
        width: 20rem;

        &-status-dot {
          width: 1.4rem;
          height: 1.4rem;
        }

        &-status-text {
          font-size: 1.6rem;
          margin-left: 1.2rem;
        }
      }

      .ant-typography {
        font-size: 1.6rem;
      }
    }

    &-content {
      width: 20rem;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
