<template>
  <div class="master-data">
    <a-skeleton v-if="loading" active />

    <div class="master-data" v-else>
      <div class="master-data-left" v-if="vessel.master_data.general_info">
        <perfect-scrollbar>
          <a-typography-title :level="5">
            General information
          </a-typography-title>

          <ul class="master-data-list">
            <li>
              <a-typography-text type="secondary">
                {{$t('fleet-page.vessel name')}}
              </a-typography-text>

              <a-typography-text>
                {{ vessel.master_data.general_info.vessel_name }}
              </a-typography-text>
            </li>

            <li>
              <a-typography-text type="secondary"
                >{{$t('fleet-page.vessel type')}}</a-typography-text
              >

              <a-typography-text>
                {{ vessel.master_data.general_info.vessel_type }}
              </a-typography-text>
            </li>
          </ul>

          <ul class="master-data-list">
            <li>
              <a-typography-text type="secondary">IMO</a-typography-text>

              <a-typography-text>
                {{ vessel.master_data.general_info.imo }}
              </a-typography-text>
            </li>

            <li>
              <a-typography-text type="secondary">E-mail</a-typography-text>

              <a-typography-text>
                {{ vessel.master_data.general_info["e_mail"] }}
              </a-typography-text>
            </li>
          </ul>

          <ul class="master-data-list">
            <li>
              <a-typography-text type="secondary">{{$t('fleet-page.deadweight')}}</a-typography-text>

              <a-typography-text>
                {{ vessel.master_data.general_info.deadweight }}
                <span>{{$t('units.mt')}}</span>
              </a-typography-text>
            </li>

            <li>
              <a-typography-text type="secondary">{{$t('fleet-page.LOA')}}</a-typography-text>

              <a-typography-text>
                {{ vessel.master_data.general_info.loa }}
                <span>{{$t('units.m')}}</span>
              </a-typography-text>
            </li>

            <li>
              <a-typography-text type="secondary">{{$t('fleet-page.BOA')}}</a-typography-text>

              <a-typography-text>
                {{ vessel.master_data.general_info.boa }} <span>{{$t('units.m')}}</span>
              </a-typography-text>
            </li>
          </ul>

          <ul class="master-data-list">
            <li>
              <a-typography-text type="secondary">
                {{$t('fleet-page.draught ballast')}}
              </a-typography-text>

              <a-typography-text>
                {{ vessel.master_data.general_info.draught.ballast }}
                <span>{{$t('units.m')}}</span>
              </a-typography-text>
            </li>

            <li>
              <a-typography-text type="secondary">
                {{$t('fleet-page.draught laden')}}
              </a-typography-text>

              <a-typography-text>
                {{ vessel.master_data.general_info.draught.laden }}
                <span>{{$t('units.m')}}</span>
              </a-typography-text>
            </li>
          </ul>

          <ul class="master-data-list">
            <li>
              <a-typography-text type="secondary"
                >{{$t('fleet-page.air draught')}}</a-typography-text
              >

              <a-typography-text>
                {{ vessel.master_data.general_info.draught.air }} <span>{{$t('units.m')}}</span>
              </a-typography-text>
            </li>
          </ul>

          <ul class="master-data-list">
            <li>
              <a-typography-text type="secondary">
                {{$t('fleet-page.last hull cleaning date')}}
              </a-typography-text>

              <a-typography-text>
                {{ vessel.master_data.general_info.last_hull_cleaning_date }}
              </a-typography-text>
            </li>

            <li>
              <a-typography-text type="secondary">{{$t('fleet-page.notes')}}</a-typography-text>

              <a-typography-text>
                {{ vessel.master_data.general_info.notes }}
              </a-typography-text>
            </li>
          </ul>
        </perfect-scrollbar>
      </div>

      <div class="master-data-right">
        <perfect-scrollbar>
          <div class="master-data-hull" v-if="vessel.master_data.hull_data">
            <a-typography-title :level="5">{{$t('fleet-page.hull data')}}</a-typography-title>

            <ul>
              <li>
                <a-typography-text type="secondary">
                  {{$t('fleet-page.block cf, cb')}}
                </a-typography-text>

                <a-typography-text>
                  {{ vessel.master_data.hull_data.c_b }}
                </a-typography-text>
              </li>

              <li>
                <a-typography-text type="secondary">
                  {{$t('fleet-page.prismatic cf, cp')}}
                </a-typography-text>

                <a-typography-text>
                  {{ vessel.master_data.hull_data.c_p }}
                </a-typography-text>
              </li>

              <li>
                <a-typography-text type="secondary">
                  {{$t('fleet-page.midship cf, cmb')}}
                </a-typography-text>

                <a-typography-text>
                  {{ vessel.master_data.hull_data.c_mb }}
                </a-typography-text>
              </li>
            </ul>
          </div>

          <div class="master-data-engine">
            <a-table
              class="table-engine"
              :dataSource="state.engineData"
              :columns="engineColumns"
              :pagination="false"
            >
              <template #headerCell="{ column }">
                <template v-if="column.key === 'title'">
                  <a-typography-title :level="5"
                    >Engine Data</a-typography-title
                  >
                </template>
              </template>

              <template #bodyCell="{ column, record }">
                <template v-if="column.dataIndex === 'field'">
                  <a-typography-text type="secondary">
                    {{ record.field }}
                  </a-typography-text>
                </template>
              </template>
            </a-table>

            <a-table
              class="table-engine"
              :dataSource="state.engineManfucaturer"
              :columns="engineManfucaturerColumns"
              :pagination="false"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.dataIndex === 'type'">
                  <a-typography-text type="secondary">
                    {{ record.type }}
                  </a-typography-text>
                </template>
              </template>
            </a-table>
          </div>

          <div class="master-data-propulsion">
            <div class="master-data-propulsion-header">
              <a-typography-title :level="5"
                >{{$t('fleet-page.propulsion data')}}</a-typography-title
              >

              <div class="master-data-propulsion-header-inner">
                <a-typography-text type="secondary">
                  {{$t('fleet-page.type of propulsion')}}
                </a-typography-text>

                <a-typography-text>
                  {{ vessel.master_data.propulsion_data.propulsion_type }}
                </a-typography-text>
              </div>
            </div>

            <a-table
              :dataSource="state.propulsionData"
              :columns="propulsionColumns"
              :pagination="false"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.dataIndex === 'order'">
                  <a-typography-text type="success">
                    {{ record.order }}
                  </a-typography-text>
                </template>
              </template>
            </a-table>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, watchEffect, reactive } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Fleetvessel.master_data",

  props: {
    vessel: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: () => true,
    },
  },

  setup(props) {
    const state = reactive({
      engineData: [],
      engineManfucaturer: [],
      propulsionData: [],
    });

    const roundingNumber = (value, unit) => {
      if (value !== "N/A") {
        return `${value.toFixed(2)} ${unit}`;
      } else {
        return value;
      }
    };

    const { t } = useI18n();

    watchEffect(() => {
      if (props.vessel.master_data) {
        state.engineData = [
          {
            field: t('fleet-page.speed'),
            laden_service: roundingNumber(
              props.vessel.master_data.engine_data.laden.service.speed,
              t('units.kn')
            ),
            laden_max: roundingNumber(
              props.vessel.master_data.engine_data.laden.max.speed,
              t('units.kn')
            ),
            ballast_service: roundingNumber(
              props.vessel.master_data.engine_data.ballast.service.speed,
              t('units.kn')
            ),
            ballast_max: roundingNumber(
              props.vessel.master_data.engine_data.ballast.max.speed,
              t('units.kn')
            ),
          },
          {
            field: t('fleet-page.fuel consumption ME'),
            laden_service: roundingNumber(
              props.vessel.master_data.engine_data.laden.service.fuel_cons_me,
              t('units.ts')
            ),
            laden_max: roundingNumber(
              props.vessel.master_data.engine_data.laden.max.fuel_cons_me,
              t('units.ts')
            ),
            ballast_service: roundingNumber(
              props.vessel.master_data.engine_data.ballast.service.fuel_cons_me,
              t('units.ts')
            ),
            ballast_max: roundingNumber(
              props.vessel.master_data.engine_data.ballast.max.fuel_cons_me,
              t('units.ts')
            ),
          },
          {
            field: t('fleet-page.fuel consumption ME aux'),
            laden_service: roundingNumber(
              props.vessel.master_data.engine_data.laden.service
                .fuel_cons_me_aux,
              t('units.ts')
            ),
            laden_max: roundingNumber(
              props.vessel.master_data.engine_data.laden.max.fuel_cons_me_aux,
              t('units.ts')
            ),
            ballast_service: roundingNumber(
              props.vessel.master_data.engine_data.ballast.service
                .fuel_cons_me_aux,
              t('units.ts')
            ),
            ballast_max: roundingNumber(
              props.vessel.master_data.engine_data.ballast.max.fuel_cons_me_aux,
              t('units.ts')
            ),
          },
        ];

        state.engineManfucaturer = [
          {
            ...props.vessel.master_data.engine_data,
            ...props.vessel.master_data.engine_data.sfoc,
          },
        ];

        state.propulsionData = Object.keys(
          props.vessel.master_data.propulsion_data.telegraph_table
        ).map((key) => ({
          order: key,
          ...props.vessel.master_data.propulsion_data.telegraph_table[key],
        }));
      }
    });

    return {
      state,
      engineColumns: [
        {
          title: t('fleet-page.engine data'),
          key: "title",
          children: [
            {
              dataIndex: "field",
              key: "field",
            },
          ],
        },
        {
          title: t('fleet-page.laden conditions'),
          key: "laden",
          children: [
            {
              title: t('fleet-page.service'),
              dataIndex: "laden_service",
              key: "laden_service",
            },
            {
              title: t('fleet-page.max'),
              dataIndex: "laden_max",
              key: "laden_max",
            },
          ],
        },
        {
          title: t('fleet-page.ballast conditions'),
          key: "ballast",
          children: [
            {
              title: t('fleet-page.service'),
              dataIndex: "ballast_service",
              key: "ballast_service",
            },
            {
              title: t('fleet-page.max'),
              dataIndex: "ballast_max",
              key: "ballast_max",
            },
          ],
        },
      ],
      engineManfucaturerColumns: [
        {
          title: t('fleet-page.engine'),
          children: [
            {
              title: t('fleet-page.manufacturer'),
              dataIndex: "manufacturer",
              key: "manufacturer",
            },
          ],
        },
        {
          children: [
            {
              title: t('fleet-page.type'),
              dataIndex: "type",
              key: "type",
            },
          ],
        },
        {
          children: [
            {
              title: t('fleet-page.max power') +", " + t('units.kw'),
              dataIndex: "max_power",
              key: "max_power",
            },
          ],
        },
        {
          title: t('fleet-page.SFOC/load, gr'),
          children: [
            {
              title: "100%",
              dataIndex: "per_100",
              key: "per_100",
            },
            {
              title: "85%",
              dataIndex: "per_85",
              key: "per_85",
            },
            {
              title: "75%",
              dataIndex: "per_75",
              key: "per_75",
            },
            {
              title: "50%",
              dataIndex: "per_50",
              key: "per_50",
            },
          ],
        },
      ],
      propulsionColumns: [
        {
          title: t('fleet-page.engine order'),
          dataIndex: "order",
          key: "order",
        },
        {
          title: t('fleet-page.speed') + ", " + t('units.kn'),
          dataIndex: "speed",
          key: "speed",
        },
        {
          title: t('fleet-page.engine power') + ", " + t('units.kw'),
          dataIndex: "power",
          key: "power",
        },
        {
          width: 120,
          title: t('RPM'),
          dataIndex: "rpm",
          key: "rpm",
        },
        {
          title: t('pitch ratio'),
          dataIndex: "pitch_ratio",
          key: "pitch_ratio",
        },
      ],
    };
  },
});
</script>

<style lang="less" scoped>
.master-data {
  width: 100%;
  background-color: @table-bg;
  display: flex;
  padding: 2.4rem 0.4rem 2.4rem 2.4rem;

  .ps {
    height: 100%;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  h5.ant-typography {
    margin-bottom: 2rem;
    color: @text-color;
  }

  :deep(.ant-table-wrapper) {
    .ant-table {
      .ant-table-container {
        border-left: 0;

        .ant-table-header {
          table {
            border-top: 0;
          }
        }

        .ant-table-thead {
          .ant-table-cell {
            background-color: @table-bg;
            border-color: transparent;
            padding-top: 0.4rem;
            padding-bottom: 1rem;
            text-transform: uppercase;
            text-align: left;

            &:before {
              display: none;
            }

            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              padding-right: 0;
            }

            h5.ant-typography {
              margin-bottom: 0;
              text-transform: none;
            }
          }
        }

        .ant-table-tbody {
          .ant-table-row {
            &:first-child {
              .ant-table-cell {
                border-top: 0.1rem solid @color-border;
              }
            }

            .ant-table-cell {
              border-color: @color-border;

              &:not(:last-child) {
                border-right: 0.1rem solid @color-border;
              }

              &:first-child {
                padding-left: 0;
              }

              &:last-child {
                padding-right: 0;
              }
            }
          }
        }
      }
    }

    &.table-engine {
      margin-bottom: 1.5rem;

      &:not(:last-child) {
        margin-bottom: 3.6rem;
      }

      .ant-table-container {
        .ant-table-thead {
          tr {
            &:first-child {
              .ant-table-cell {
                padding-top: 0;
                padding-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  &-left {
    min-width: 40rem;
    border-right: 0.1rem solid @color-border;
    padding-right: 0.4rem;
  }

  &-right {
    flex: auto;

    :deep(.ps) {
      padding-right: 2.4rem;
    }
  }

  &-list {
    &:not(:last-child) {
      margin-bottom: 4.4rem;
    }

    li {
      display: flex;
      align-items: center;
      gap: 2.4rem;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      .ant-typography:not(.ant-typography-secondary) {
        span {
          color: @color-secondary;
          margin-left: 0.6rem;
        }
      }

      .ant-typography-secondary {
        width: 15rem;
      }
    }
  }

  &-hull {
    border-bottom: 0.1rem solid @color-border;
    padding-left: 3rem;
    padding-bottom: 3rem;

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;

      li {
        .ant-typography-secondary {
          margin-right: 1.2rem;
        }
      }
    }
  }

  &-engine {
    border-bottom: 0.1rem solid @color-border;
    padding: 3rem 0 3rem 3rem;
  }

  &-propulsion {
    padding-left: 3rem;
    padding-top: 3rem;

    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      &-inner {
        display: flex;
        align-items: center;
        gap: 2.4rem;
        margin-left: 10rem;
      }

      h5.ant-typography {
        margin-bottom: 0;
      }
    }
  }
}
</style>